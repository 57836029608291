import React from "react";
import { ReactComponent as Landworks } from "../../../assets/svg/graphics/landworks.svg";
import { ReactComponent as Metaportal } from "../../../assets/svg/graphics/metaportal.svg";

import "./products-list.scss";

export default function Products() {
  return (
    <section className="products-list">
      <div className="content">
        <div className="landworks list-item">
          <Landworks />
          <div className="column">
            <h2>
              Landworks
            </h2>
            <p>
              LandWorks is a{" "}
              <strong>
                metaverse land renting marketplace based on Ethereum
              </strong>
              . The protocol enables period-based renting of land in Web3
              metaverse games.
            </p>
            <p>
              LandWorks will start off with Decentraland as a primer and then
              gradually expand to support other prominent games. It will enable
              landowners to <strong>earn passive income on their assets</strong>
              , while renters looking to leverage metaverse games will be able
              to easily rent instead of buying virtual land properties.
            </p>
          </div>
        </div>
        <div className="metaportal list-item">
          <Metaportal />
          <div className="column">
            <h2>
              Metaportal
            </h2>
            <p>
              MetaPortal is a cross-platform Web3-enabled desktop app serving as
              a single point of entry/access to different Web3 metaverse games.
              <strong>
              &nbsp;Essentially serving as a portal to Web3 Metaverse games.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
