import React from "react";
import Hero from "../components/Sections/Hero";
import Products from "../components/Sections/Products";
import ProductsList from "../components/Sections/ProductsList";
import Redeem from "../components/Sections/Redeem";

export default function Landing() {
  return (
    <>
      <section className="section hero-section">
        <Hero />
      </section>
      <section className="section redeem-section">
        <Redeem />
      </section>
      <section className="section products-section">
        <Products />
      </section>
      <section className="section products-list-section">
        <ProductsList />
      </section>
    </>
  );
}
