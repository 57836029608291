import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import Landing from "./pages/landing";
import ScrollToTop from "./utils/ScrollToTop";

import "./App.scss";

export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <div>
        <Header />

        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>
        </Switch>

        <Footer />
      </div>
    </Router>
  );
}
