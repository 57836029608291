import React, { useState } from "react";
import { ReactComponent as Twitter } from "../../assets/svg/social/twitter.svg";
import { ReactComponent as Discord } from "../../assets/svg/social/discord.svg";
import { ReactComponent as Medium } from "../../assets/svg/social/medium.svg";
import { ReactComponent as Coingecko } from "../../assets/svg/social/coingecko.svg";
import { ReactComponent as FooterStarsLeft } from "../../assets/svg/graphics/footer-stars-left.svg";
import { ReactComponent as FooterStarsRight } from "../../assets/svg/graphics/footer-stars-right.svg";
import EnterDaoLogo from "../../assets/png/enterdao-logo.png";

import "./footer.scss";

export default function Footer() {
  const [email, setEmail] = useState("");

  const getYear = () => {
    return new Date().getFullYear();
  };

  return (
    <footer>
      <div className="graphics">
        <div className="content">
          <FooterStarsLeft />
          <FooterStarsRight />
        </div>
      </div>
      <div className="wrapper">
        <div className="content">
          <section className="social">
            <div className="newsletter">
              <p>Stay up to date with our newsletter</p>
              <div className="subscribe">
                <form
                  action="https://xyz.us5.list-manage.com/subscribe/post"
                  method="POST"
                  noValidate
                  target="_blank"
                >
                  <input
                    type="hidden"
                    name="u"
                    value="8746db1bbacb6ab8a19400196"
                  />
                  <input type="hidden" name="id" value="33f89e7834" />
                  <input
                    className="primary"
                    placeholder="Enter your email"
                    type="email"
                    name="EMAIL"
                    id="MERGE0"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoCapitalize="off"
                    autoCorrect="off"
                  />
                  <button type="submit" className="primary">
                    Subscribe
                  </button>
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                    aria-label="Please leave the following three fields empty"
                  >
                    <label htmlFor="b_email">Email: </label>
                    <input
                      type="email"
                      name="b_email"
                      tabIndex={-1}
                      value=""
                      placeholder="youremail@gmail.com"
                      id="b_email"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="community">
              <p>Join our community</p>
              <ul>
                <li>
                  <a
                    href="https://twitter.com/EnterDao"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter />
                  </a>
                </li>
                <li>
                  <a
                    href="https://discord.gg/sDC73RGQb3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Discord />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.coingecko.com/en/coins/enterdao"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Coingecko />
                  </a>
                </li>
                <li>
                  <a
                    href="https://medium.com/EnterDao"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Medium />
                  </a>
                </li>
              </ul>
            </div>
          </section>
          <section className="details">
            <div className="info">
              <img src={EnterDaoLogo} alt="EnterDAO" />
              <p>
                EnterDAO is a decentralized autonomous organization on the
                Ethereum blockchain founded with the mission to build products
                enabling new markets within the metaverse economy. EnterDAO is
                governed by the ENTR token.
              </p>
            </div>
            <div className="sitemap">
              <div className="row">
                <p>Products</p>
                <ul>
                  <li>
                    <a
                      href="https://landworks.xyz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LandWorks
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://metaportal.gg/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MetaPortal
                    </a>
                  </li>
                </ul>
              </div>
              {/* <div className="row">
                <p>NFT Drops</p>
                <ul>
                  <li>
                      <a
                        href="https://sharded-minds.enterdao.xyz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Sharded Minds
                      </a>
                    </li>
                </ul>
              </div> */}
              {/* <div className="row">
                <p>Info</p>
                <ul>
                  <li>
                    <a
                      href="https://medium.com/enterdao/enterdao-whitepaper-27447f7400c8"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Whitepaper
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://docs.enterdao.xyz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Docs
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/EnterDAO/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Github
                    </a>
                  </li>
                </ul>
              </div> */}
              <div className="row">
                <p>DAO</p>
                <ul>
                  <li>
                    <a
                      href="https://dao.enterdao.xyz/governance"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Governance
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://dao.enterdao.xyz/yield-farming"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Yield Farming
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://dao.enterdao.xyz/airdrop"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Airdrop
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
          <section className="copyright">
            <div className="company text_small">
              EnterDAO.xyz &#169; {getYear()}. Open-sourced.
            </div>
            <ul className="links text_small">
              <li>
                <a
                  href="https://app.sushi.com/swap?inputCurrency=0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48&outputCurrency=0xd779eea9936b4e323cddff2529eb6f13d0a4d66e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SushiSwap USDC/ENTR market
                </a>
              </li>
              <li>
                <a
                  href="https://app.sushi.com/add/0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48/0xd779eea9936b4e323cddff2529eb6f13d0a4d66e"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Add liquidity to SushiSwap USDC/ENTR pool
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </footer>
  );
}
