import React from "react";
import { ReactComponent as ProductsGraphics } from "../../../assets/svg/graphics/products.svg";
import { ReactComponent as ProductsTop } from "../../../assets/svg/graphics/products-seamless-top.svg";
import { ReactComponent as ProductsBottom } from "../../../assets/svg/graphics/products-seamless-bottom.svg";

import "./products.scss";

export default function Products() {
  return (
    <section className="products">
      <div className="content">
        <ProductsTop className="top" />
        <ProductsBottom className="bottom" />
        <ProductsGraphics className="graphics" />
        <div className="row">
          <div className="column">
            <h2>EnterDAO and its products</h2>
            <p>
              Through a lineup of core products EnterDAO aims to&nbsp;
              <strong>
                empower users, metaverse land owners, investors, brands and
                degens of all kinds
              </strong>
              &nbsp;by tackling pain points like access to metaverse land, its
              capital efficiency, as well as UX and onboarding of metaverse
              games.
            </p>
            <p>
              EnterDAO is set to build 2 core products — 
              <strong>LandWorks</strong> and&nbsp;<strong>MetaPortal</strong>,
              as well as an NFT drop called Sharded Minds.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
