import React from "react";
import { ReactComponent as Tokens } from "../../../assets/svg/graphics/tokens.svg";

import "./redeem.scss";
export default function Redeem() {
  return (
    <section className="redeem">
      <div className="redeem-info">
        <h2>
          REDEEM <b>ETH</b> FOR <b>ENTR</b>
        </h2>
        <p>
          Use the ENTR Redemption Portal to redeem your ENTR for ETH. You can
          redeem your ENTR at a fixed rate of 0.0025376 ETH until November 2nd
          2024 at 23h59 UTC.
        </p>
        <a
          href="https://dao.enterdao.xyz/redeem"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="tertriary">
            <span>Redeem ETH</span>
          </button>
        </a>
      </div>

      <div className="image-container">
        <Tokens/>
      </div>
    </section>
  );
}
