import React from "react";
import { ReactComponent as StarsHeaderLeft } from "../../../assets/svg/graphics/stars-header-left.svg";
import { ReactComponent as StarsHeaderRight } from "../../../assets/svg/graphics/stars-header-right.svg";
import { ReactComponent as Metaverse } from "../../../assets/svg/graphics/metaverse.svg";
import { ReactComponent as Terrain } from "../../../assets/svg/graphics/terrain.svg";

import "./hero.scss";

export default function Hero() {
  return (
    <section className="hero">
      <div className="content">
        <div className="stars">
          <StarsHeaderLeft />
          <StarsHeaderRight />
        </div>
        <div className="title">
          <h2>Building a</h2>
          <h1>Gateway to the metaverse</h1>
          <p>
            EnterDAO is a decentralized autonomous organization (DAO) building
            products to enable new markets within the Web3 metaverse economy.
          </p>
        </div>
        <div className="actions">
          <a
            href="https://medium.com/enterdao/enterdao-whitepaper-27447f7400c8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="primary">Whitepaper</button>
          </a>
          <a
            href="https://dao.enterdao.xyz/governance"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="secondary">
              <span>Enter Governance</span>
            </button>
          </a>
        </div>
        <div className="metaverse">
          <Metaverse />
        </div>
      </div>
      <div className="terrain">
        <Terrain />
      </div>
    </section>
  );
}
