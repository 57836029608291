import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../../assets/svg/dropdown-arrow.svg";
import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { ReactComponent as HamburgerIcon } from "../../assets/svg/menu.svg";
import { ReactComponent as GovernanceIcon } from "../../assets/svg/icons/governance.svg";
import { ReactComponent as YieldFarmingIcon } from "../../assets/svg/icons/yield-farming.svg";
import { ReactComponent as AirdropIcon } from "../../assets/svg/icons/airdrop.svg";
import { ReactComponent as DocsIcon } from "../../assets/svg/icons/docs.svg";
import { ReactComponent as WhitepaperIcon } from "../../assets/svg/icons/whitepaper.svg";

import "./header.scss";

export default function Header() {
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(!open);

  return (
    <header>
      <div className="content">
        <div className="wrapper">
          <Link to="/" className="title">
            <Logo />
            <h5>EnterDAO</h5>
          </Link>
          <nav>
            <div className="nav-btn" onClick={handleClick}>
              <HamburgerIcon />
            </div>
            {open && (
              <div className="mobile-nav">
                <ul>
                  <li>
                    <div className="mobile-item">
                      <span>Products</span>
                      <ul className="menu">
                        <li>
                          <a
                            href="https://landworks.xyz/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LandWorks
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://metaportal.gg/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            MetaPortal
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                  {/* <li>
                    <div className="mobile-item">
                      <span>NFT Drops</span>
                      <ul className="menu">
                        <li>
                          <a
                            href="https://sharded-minds.enterdao.xyz"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Sharded Minds
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li> */}
                  {/* <li>
                    <div className="mobile-item">
                      <span>Info</span>
                      <ul className="menu">
                        <li>
                          <a
                            href="https://medium.com/enterdao/enterdao-whitepaper-27447f7400c8"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleClick}
                          >
                            <WhitepaperIcon /> Whitepaper
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://docs.enterdao.xyz/"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleClick}
                          >
                            <DocsIcon />
                            Docs
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li> */}
                  <li>
                    <div className="mobile-item">
                      <span>DAO</span>
                      <ul className="menu last">
                        <li>
                          <a
                            href="https://dao.enterdao.xyz/governance"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleClick}
                          >
                            <GovernanceIcon /> Governance
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://dao.enterdao.xyz/yield-farming"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleClick}
                          >
                            <YieldFarmingIcon /> Yield Farming
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://dao.enterdao.xyz/airdrop"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={handleClick}
                          >
                            <AirdropIcon /> Airdrop
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            )}
            <ul>
              <li>
                <div className="menu-item">
                  Products <Arrow />
                  <ul className="menu">
                    <li>
                      <a
                        href="https://landworks.xyz/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        LandWorks
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://metaportal.gg/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        MetaPortal
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              {/* <li>
                <div className="menu-item">
                  NFT Drops <Arrow />
                  <ul className="menu">
                    <li>
                      <a
                        href="https://sharded-minds.enterdao.xyz"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Sharded Minds
                      </a>
                    </li>
                  </ul>
                </div>
              </li> */}
              {/* <li>
                <div className="menu-item">
                  Info <Arrow />
                  <ul className="menu">
                    <li>
                      <a
                        href="https://medium.com/enterdao/enterdao-whitepaper-27447f7400c8"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <WhitepaperIcon />
                        Whitepaper
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://docs.enterdao.xyz/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DocsIcon />
                        Docs
                      </a>
                    </li>
                  </ul>
                </div>
              </li> */}
              <li>
                <div className="menu-item">
                  DAO <Arrow />
                  <ul className="menu last">
                    <li>
                      <a
                        href="https://dao.enterdao.xyz/governance"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <GovernanceIcon />
                        Governance
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://dao.enterdao.xyz/yield-farming"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <YieldFarmingIcon />
                        Yield Farming
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://dao.enterdao.xyz/airdrop"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AirdropIcon />
                        Airdrop
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}
